import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import React, { useEffect, useState } from "react";
import { useGetDiningAreasQuery } from "store/apis/DiningAreasApi";
import { useAddResidentOrderMutation, useDeleteHeldOrderMutation, usePrintTogetherOrdersMutation } from "store/apis/OrdersApi";
import PencilIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, FormControlLabel, Grid, IconButton, Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectHeldViewLastTableFilter, selectHeldViewLastWingFilter, selectShowOnlyUserOrders, setGuestInfo, setHeldViewLastTableFilter, setHeldViewLastWingFilter, setMenuInfo, setShowOnlyUserOrders } from "store/slices/orderSlice/OrderSlice";
import DeleteModal from "components/shared/deleteModal/DeleteModal";
import { CustomNoRowsOverlay } from "components/admin/admin.overlays";
import { useGetResidentsAllQuery } from "store/apis/ResidentsApi";
import { DEFAULT_EMPTY, DIRECT_PRINT_DATE_OPTIONS, PAYMENT_TYPES } from "store/store.constants";
import SelectDiningArea from "components/shared/selectDiningArea/SelectDiningArea";
import SelectTable from "components/shared/selectTable/SelectTable";
import SelectWing from "components/shared/selectWing/SelectWing";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import "./HeldOrders.css";
import { selectHeldViewLastDiningArea, setHeldViewLastDiningArea } from "store/slices/diningAreaSlice/DiningAreaSlice";

const printStyles = {
  printGeneral: {
    display: 'flex',
    paddingBottom: '0px',
    paddingTop: '0px'
  },
  printDiv: {
    paddingBottom: '0px',
    paddingTop: '0px'
  },
}

export function TotalRowsFooterComponent(props) {
  return (
    <Grid container display="flex" justifyContent="space-between" alignItems="center" sx={{borderTop: "1px solid lightgray", height: "3rem"}}>
      <Grid item paddingX={1}>
        <FormControlLabel control={<Switch checked={props?.showUserOrdersOnly} onClick={() => props.handleToggle()} />} label={props?.showUserOrdersOnly ? "Showing User's Orders" : "Showing All Orders"}/>
      </Grid>
      <Grid item paddingX={1}>
        Total Rows: {props?.ordersData?.length || 0}
      </Grid> 
    </Grid>
  );
}

export default function HeldOrders({heldOrders, currentUserData, isDirectPrint, handlePrint}) {
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const lastDiningArea = useSelector(selectHeldViewLastDiningArea);
  const lastTable = useSelector(selectHeldViewLastTableFilter);
  const lastWing = useSelector(selectHeldViewLastWingFilter);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedForDeleteId, setSelectedForDeleteId] = useState(null);
  const [showUserOrdersOnly, setShowUserOrdersOnly] = useState(useSelector(selectShowOnlyUserOrders));
  const [displayedOrders, setDisplayedOrders] = useState([]);

  const [diningOpen, setDiningOpen] = useState(false);
  const [selectedDiningAreaId, setSelectedDiningAreaId] = useState("all");
  const [selectedDiningAreaName, setSelectedDiningAreaName] = useState("All");
  const [tableOpen, setTableOpen] = useState(false);
  const [tableOptions, setTableOptions] = useState([]);
  const [selectedTable, setSelectedTable] = useState(lastTable || 'all');
  const [wingOpen, setWingOpen] = useState(false);
  const [wingOptions, setWingOptions] = useState([]);
  const [selectedWing, setSelectedWing] = useState(lastWing || 'all');

  const {
    data: diningAreas,
    isFetching: fetchingAreas,
    isError: areasError
  } = useGetDiningAreasQuery();

  useEffect(() => {
    diningAreaSelected(lastDiningArea?.diningAreaId || 'all', lastDiningArea?.diningAreaName || 'All');
  }, [diningAreas]);

  const {
    data: residents,
    isFetching: fetchingResidents,
  } = useGetResidentsAllQuery();

  useEffect(() => {
    //Build out wing and table? options lists
    if (residents?.length && wingOptions?.length === 0) {
      const wingOptions = new Set();
      residents.forEach((res) => {
        if (res?.wing) {
          wingOptions.add(res.wing?.toLowerCase());
        }
      });
      setWingOptions(Array.from(wingOptions));
    }
  
  }, [residents]);
  
  //filtering
  useEffect(() => {
    if (heldOrders?.length) {
      let filteredList = [...heldOrders];
      if(selectedDiningAreaId !== 'all') {
        filteredList = filteredList.filter((held) => held.order.diningAreaId === selectedDiningAreaId);
      }

      if (selectedTable !== 'all') {
        filteredList = filteredList.filter((held) => held.order.tableName === selectedTable);
      }

      if (selectedWing !== 'all') {
        filteredList = filteredList.filter((held) => held.wing?.toUpperCase() === selectedWing?.toUpperCase())
      }

      setDisplayedOrders([...filteredList]);
    }
  }, [selectedDiningAreaId, selectedWing, selectedTable, displayedOrders]);

  //Need new state to delineate resident filter saving and held order filter saving
  const diningAreaSelected = (diningAreaId, diningAreaName) => {
    setDiningOpen(false);
    if (!diningAreaId && !diningAreaName) {
      return;
    }
    setSelectedDiningAreaId(diningAreaId);
    setSelectedDiningAreaName(diningAreaName);
    const selectedDiningArea = diningAreas?.find((area) => area.id === diningAreaId);
    if (!selectedDiningArea?.tables.find((table) => table?.name === selectedTable)) {
      setSelectedTable('all');
    }
    setTableOptions(selectedDiningArea?.tables);
    dispatch(setHeldViewLastDiningArea({ diningAreaId: diningAreaId, diningAreaName: diningAreaName }))
  }

  const tableSelected = (tableName) => {
    setTableOpen(false);
    if(tableName) {
      setSelectedTable(tableName);
      dispatch(setHeldViewLastTableFilter(tableName));
    }
  }

  const wingSelected = (wingName) => {
    setWingOpen(false);
    if (wingName) {
      setSelectedWing(wingName);
      dispatch(setHeldViewLastWingFilter(wingName));
    }
  }

  const [
    submitHeldOrder
  ] = useAddResidentOrderMutation();

  const [
    submitPrintTogetherList
  ] = usePrintTogetherOrdersMutation();

  const [
    deleteHeldOrder
  ] = useDeleteHeldOrderMutation();

  const submitCheckedOrders = () => {
    if (isDirectPrint) {
      const html = document.getElementById('printSection').cloneNode(true);
      handlePrint(html);
    }

    selectedOrderIds?.forEach(async (orderId) => {
      const orderToSubmit = heldOrders?.find((order) => order?.order?.id === orderId);

      if (orderToSubmit) {
        await submitHeldOrder(orderToSubmit).then((res) => {
          if (res?.data?.success) {
            deleteHeldOrder(orderToSubmit?.order?.id);
          }
        });
      }
    });
  }

  const printCheckedOrdersTogether = async () => {
    if (isDirectPrint) {
      const html = document.getElementById('printSection').cloneNode(true);
      handlePrint(html);
    }

    const ordersToSubmit = heldOrders?.filter((order) => selectedOrderIds.includes(order?.order?.id));

    if (ordersToSubmit?.length) {
      await submitPrintTogetherList(ordersToSubmit).then((res) => {
        if (res?.data?.success) {
          ordersToSubmit.forEach((submitted) => {
            deleteHeldOrder(submitted?.order?.id);
          });
        }
      });
    }
  }

  const handleUserOrderToggle = () => {
    setShowUserOrdersOnly((prev) => {
      dispatch(setShowOnlyUserOrders(!prev));
      return !prev;
    });
  }

  useEffect(() => {
    //not a length check just need an empty array at minimum in case user clears list
    if (heldOrders) {
      setDisplayedOrders([...heldOrders]);
    }
  }, [heldOrders]);

  useEffect(() => {
    if (heldOrders?.length) {
      if (showUserOrdersOnly) {
        setDisplayedOrders([...heldOrders.filter((heldOrder) => {
          return heldOrder?.order?.userLogin?.id === currentUserData?.id
        })])
      } else {
        setDisplayedOrders([...heldOrders]);
      }
    }
  }, [showUserOrdersOnly])

  const handleEditOrder = (rowData) => {
    const residentId = rowData.order.residentId;
    const tsSetup = rowData.order.tableSideSetupId;

    dispatch(setMenuInfo(rowData));
    if (rowData?.order?.guest?.length) {
      dispatch(setGuestInfo(
        {
          guestName: rowData?.order?.guest,
          paymentDone: rowData?.order?.guestBillingTypeId,
          selectedResident: rowData?.order?.guestOfResidentId || 0,
          selectedGuestType: rowData?.order?.guestTypeId 
        }
      ));

      navigate({
        pathname: `/takeorder/guest/order`,
        search: '?from=held'
      });
    } else {
      navigate({
        pathname: `/takeorder/residents/order/${residentId}/${tsSetup}`,
        search: "?current=true&from=held"
      });
    }
  }

  const handleDeleteOrder = (rowData) => {
    setSelectedForDeleteId(rowData?.order?.id);
    setShowDeleteModal(true);
  }

  const heldOrderColumns = [
    { 
      field: "residentName", 
      headerName: "Full Name", 
      flex: 2,
      height: "auto",
      valueGetter: (value, row) => {
        return row?.residentName?.trim() || row?.order?.guest;
      }
    },
    { 
      field: "order.diningAreaId", 
      headerName: "Dining Area", 
      flex: 2,
      valueGetter: (value, row) => { 
        return diningAreas?.find((area) => area.id === row?.order?.diningAreaId)?.name;
      }
    },
    { 
      field: "tableName", 
      headerName: "Table Name", 
      flex: 2,
      valueGetter: (value, row) => {
        return row?.order?.tableName;
      }
    },
    { 
      field: "actions", 
      sortable: false,
      disableColumnMenu: true,
      maxWidth: 150,
      headerName: "Actions", 
      flex: 2,
      renderCell: (params) => {
        return (
          <div style={{
            display: "flex", 
            flex: "0 0 auto", 
            alignItems: "center", 
            justifyContent: "center", 
            maxHeight: "1.375rem", 
            paddingBottom: ".5rem"
            }}
          >
            <IconButton 
              sx={{
                backgroundColor: "var(--yellowColor)", 
                color: "#494a50", 
                borderRadius: ".5rem", 
                "&:hover": {backgroundColor: "#d5b538", color: "#fff"}
              }} 
              aria-label="Edit"
              onClick={() => handleEditOrder(params.row)}
              >
              <PencilIcon sx={{fontSize: "1rem"}} />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: "var(--redColor)", 
                color: "#FFF",
                borderRadius: ".5rem", 
                marginLeft: ".5rem",
                "&:hover": {backgroundColor: "var(--darkGrayColor)"}
              }}
              aria-label="Delete"
              onClick={() => handleDeleteOrder(params.row)}
            >
              <DeleteIcon sx={{alignSelf: "center", fontSize: "1rem"}} />
            </IconButton>
          </div>
        )
      }
    }
  ];

  const getResidentNameById = (residentId) => {
    return residents?.find((resident) => resident.id === residentId)?.name?.split("(")?.[0]?.trim() || "";
  }

  const getResidentWingById = (residentId) => {
    return residents?.find((resident) => resident.id === residentId)?.wing || "";
  }

  const getDiningAreaNameById = (diningAreaId) => {
    return diningAreas?.find((area) => area.id === diningAreaId)?.name?.trim() || "";
  }

  const renderPrintItems = (heldOrder) => {
    const menuList = [];

    heldOrder?.menuItems?.forEach((item) => {
      const quantity = item.quantity >= 1 ? item.quantity.toFixed(0) : item.quantity.toFixed(1);

      menuList.push(
        <div key={item.itemId} style={printStyles.printDiv}>
          <span>{quantity} - <b>{item.name}</b> {`${item.portionSize ? `(${item.portionSize})` : ""}`}</span>
        </div>
      );

      if (item?.removedItems?.length) {
        item.removedItems.forEach((removed, idx) => {
          menuList.push(
            <div key={`${removed.id}-${idx}`} style={{paddingY: "0px", paddingLeft: "24px"}}>
              <span><i>{`Remove - ${removed.text}`}</i></span>
            </div>
          )
        });
      }

      if (item?.allergyNote) {
        menuList.push(
          <div key={`${item.itemId}-allergyNote`} style={{paddingY: "0px", paddingLeft: "24px"}}>
            <span><i>{`${item.allergyNote}`}</i></span>
          </div>
        );
      }

      if (item?.specialNote) {
        menuList.push(
          <div key={`${item.itemId}-specialNote`} style={{paddingY: "0px", paddingLeft: "24px"}}>
            <span><b>{`Special Note: ${item.specialNote}`}</b></span>
          </div>
        );
      }

      item.subItemList?.forEach((sub) => {
        menuList.push(
          <div key={sub.recipeId} style={{paddingY: "0px", paddingLeft: "24px"}}>
            <span>{`Add ${sub.quantity} - ${sub.recipeName}`}</span>
          </div>
        )
      });
    });

    heldOrder?.alternateItems?.forEach((alt) => {
      const quantity = alt.quantity >= 1 ? alt.quantity.toFixed(0) : alt.quantity.toFixed(1);

      menuList.push(
        <div key={alt.itemId} style={printStyles.printDiv}>
          <span>{quantity} - <b>{alt.name}</b> {`${alt.portionSize ? `(${alt.portionSize})` : ""}`}</span>
        </div>
      );

      if (alt?.removedItems?.length) {
        alt.removedItems.forEach((removed, idx) => {
          menuList.push(
            <div key={`${removed.id}-${idx}`} style={{paddingY: "0px", paddingLeft: "24px"}}>
              <span><i>{`Remove - ${removed.text}`}</i></span>
            </div>
          )
        });
      }

      if (alt?.allergyNote) {
        menuList.push(
          <div key={`${alt.itemId}-allergyNote`} style={{paddingY: "0px", paddingLeft: "24px"}}>
            <span><i>{`${alt.allergyNote}`}</i></span>
          </div>
        );
      }

      if (alt?.specialNote) {
        menuList.push(
          <div key={`${alt.itemId}-specialNote`} style={{paddingY: "0px", paddingLeft: "24px"}}>
            <span><b>{`Special Note: ${alt.specialNote}`}</b></span>
          </div>
        );
      }

      alt.subItemList?.forEach((sub) => {
        menuList.push(
          <div key={sub.recipeId} style={{paddingY: "0px", paddingLeft: "24px"}}>
            <span>{`Add ${sub.quantity} - ${sub.recipeName}`}</span>
          </div>
        )
      });
    });

    return menuList;
  }

  const renderPrintContent = () => {
    const selectedHeldOrders = heldOrders?.filter((held) => selectedOrderIds?.includes(held?.order?.id));
    const printOrderList = [];
    
    selectedHeldOrders?.forEach((heldOrder) => {
      const guestResidentName = getResidentNameById(heldOrder?.order?.guestOfResidentId);
      const residentWing = getResidentWingById(heldOrder?.order?.residentId);
      const diningAreaName = getDiningAreaNameById(heldOrder?.order?.diningAreaId);
      const isIsolation = residents?.find((res) => res.id == heldOrder?.order?.residentId)?.isIsolation;
      printOrderList.push(
        <div key={heldOrder?.order?.id} style={{padding: "10px 5px 5px 5px", fontSize: "15px", maxWidth: "300px"}}>
            <div style={{fontSize: "1.25rem", fontWeight: "bold", paddingY: "5px"}}>
              { heldOrder.order.residentId === 0 ? heldOrder.order.guest : heldOrder.residentName }
            </div> 
            {isIsolation && <div style={{fontWeight: "bold", paddingY: "5px"}}>
              * ISOLATION *
            </div>
            } 
            {guestResidentName !== "" &&
              <div style={printStyles.printDiv, {fontSize: ".75rem"}}>
                Guest of: {guestResidentName}
              </div>
            }
            {heldOrder?.order?.residentId === 0 &&
              <div style={printStyles.printDiv, {fontSize: ".75rem"}}>
                Payment Status: {PAYMENT_TYPES[heldOrder?.order?.guestBillingTypeId]}
              </div>
            }
            {diningAreaName !== "" && 
              <div style={printStyles.printGeneral}>
                <span style={{marginRight: ".75rem", fontWeight: "bold", fontSize: ".875rem"}}>
                  {diningAreaName.replace(/Dining Room/gi, 'DR')}
                </span>
                <span style={{fontWeight: "bold", fontSize: ".875rem"}}>
                  {heldOrder.order.tableName ? `Table: ${heldOrder.order.tableName}` : null}
                </span>
              </div>
            }
            {!DEFAULT_EMPTY.includes(heldOrder.roomBed) &&
              <div style={printStyles.printGeneral}>
                <span style={{fontSize: ".75rem", marginBottom: ".5rem"}}>
                  {`Wing: ${residentWing || 'N/A'}`}
                </span>
                <span style={{margin: "0 .5rem", fontSize: ".75rem"}}>|</span>
                <span style={{fontSize: ".75rem"}}>
                  {`Room: ${heldOrder.roomBed}`}
                </span>
              </div>
            }

            <hr style={{marginBottom: "6px", border: "1px solid black"}} />

            <div style={{display: "flex", fontSize: ".675rem"}}>
              <div id="label-col" style={{minWidth: "75px"}}>
                {!DEFAULT_EMPTY.includes(heldOrder.dietName) && <div>Diet:</div> }
                {!DEFAULT_EMPTY.includes(heldOrder.dietTextureName) && <div>Texture:</div> }
                {!DEFAULT_EMPTY.includes(heldOrder.dietOther) && <div>Other:</div> }
                {!DEFAULT_EMPTY.includes(heldOrder.liquidConsistency) && <div>Liquid:</div> }
                {!DEFAULT_EMPTY.includes(heldOrder.allergies) && <div><i>Allergies:</i></div> }
              </div>
              <div id="value-col" style={{whiteSpace: "pre"}}>
                {!DEFAULT_EMPTY.includes(heldOrder.dietName) && <div><b>{heldOrder.dietName}</b></div>}
                {!DEFAULT_EMPTY.includes(heldOrder.dietTextureName) && <div><b>{heldOrder.dietTextureName}</b></div>}
                {!DEFAULT_EMPTY.includes(heldOrder.dietOther) && <div><b>{heldOrder.dietOther}</b></div>}
                {!DEFAULT_EMPTY.includes(heldOrder.liquidConsistency) && <div><b>{heldOrder.liquidConsistency}</b></div>}
                {!DEFAULT_EMPTY.includes(heldOrder.allergies) && <div><i>{heldOrder.allergies}</i></div>}
              </div>
            </div>

            <hr style={{marginTop: "6px", border: "1px solid black"}} />

            <div style={{marginTop: "1rem", fontSize: ".75rem", marginBottom: "1rem"}}>
              { renderPrintItems(heldOrder) }
              <div style={printStyles.printGeneral}>
                  <i>Added Notes: {heldOrder.order.note || ""}</i>
                </div>
            </div>

            <hr style={{marginBottom: "6px", border: "1px solid black"}} />

            <div style={{marginBottom: "1.5rem", fontSize: ".75rem"}}>
              {!!heldOrder.notes?.length &&
                <div style={printStyles.printGeneral}>
                  MealCard Notes: {heldOrder.notes.join(', ')}
                </div>
              }
              {!DEFAULT_EMPTY.includes(heldOrder.adaptiveEquipment) &&
                <div style={printStyles.printGeneral}>
                  Adaptive Equip: {heldOrder.adaptiveEquipment}
                </div>
              }
              {!DEFAULT_EMPTY.includes(heldOrder.fluidRestriction) &&
                <div style={printStyles.printGeneral}>
                  Fluid Restrict: {heldOrder.fluidRestriction}
                </div>
              }
            </div>

            <div style={{fontSize: ".75rem"}}>
              <div>
                <span><b>Total Price:</b> ${heldOrder.order?.guestPrice?.toFixed(2)}</span>
              </div>
              <div>
                <span><b>Signature: __________________________________</b></span>
              </div>
            </div>
            <div style={{fontSize: "1.25rem", fontWeight: "bold", textAlign: "center"}}>
              { heldOrder.order.residentId === 0 ? heldOrder.order.guest : heldOrder.residentName }
            </div> 
          <div style={{marginTop: "5px", paddingY: "5px", fontSize: "10px"}}>
            {`Order Taken: ${new Date().toLocaleString('en-US', DIRECT_PRINT_DATE_OPTIONS)}, by ${currentUserData?.firstName || ""} ${currentUserData?.lastName || ""}`}
          </div>
          {selectedHeldOrders.indexOf(heldOrder) !== selectedHeldOrders.length - 1 &&
            <p style={{pageBreakBefore: 'always'}}></p>
          }
        </div>
      );
    });

    return printOrderList;
  }

  return (
    <div className="paperContent customScrollbar" style={{backgroundColor: "#FFF"}}>
      <Grid container justifyContent="center" spacing={1} sx={{marginBottom: "1rem"}}>
        <Grid item xs={6}>
          <Button 
            id="dining-btn"
            fullWidth 
            variant="contained" 
            onClick={() => setDiningOpen(true)}
            endIcon={<ExpandIcon />}
          >
            Dining Area: {selectedDiningAreaName}
          </Button>
          <SelectDiningArea
            open={diningOpen} 
            diningAreas={diningAreas}  
            itemSelected={diningAreaSelected}
            title="Select Dining Area"
            enableAll={true}
            />
        </Grid>
        <Grid item xs={3}>
          <Button   
            id="dining-btn"
            fullWidth 
            variant="contained" 
            onClick={() => setTableOpen(true)}
            endIcon={<ExpandIcon />}
          >
            Table: {selectedTable}
          </Button>
          <SelectTable
            open={tableOpen}
            tables={tableOptions}
            itemSelected={tableSelected}
            title="Select Table"
            enableAll={true}
          />
        </Grid>
        <Grid item xs={3}>
          <Button 
            id="dining-btn"
            fullWidth 
            variant="contained" 
            onClick={() => setWingOpen(true)}
            endIcon={<ExpandIcon />}
          >
            Wing: {selectedWing}
          </Button>
          <SelectWing
            open={wingOpen}
            wings={wingOptions}
            itemSelected={wingSelected}
            title="Select Wing"
            enableAll={true}
            endIcon={<ExpandIcon />}
          />
        </Grid>
      </Grid>  
      <BaseDataGrid
        id="heldGrid"
        rows={displayedOrders}
        columns={heldOrderColumns}
        loading={fetchingAreas || fetchingResidents}
        error={areasError}
        height={selectedOrderIds?.length ? "80%" : "90%"}
        disableRowSelectionOnClick={false}
        disableSelectionOnClick={false}
        width={"100%"}
        pagination={false}
        getRowId={(row) => row?.order?.id}
        EmptyRowMessage={"No Held Orders Found"}
        density={"standard"}
        autoHeight={false}
        checkboxSelection
        onRowSelectionModelChange={(newRows) => {
          setSelectedOrderIds(newRows);
        }}
        rowSelectionModel={selectedOrderIds}
        slots={{
          footer: TotalRowsFooterComponent,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          footer: {ordersData: displayedOrders, showUserOrdersOnly: showUserOrdersOnly, handleToggle: handleUserOrderToggle }
        }}
        sx={{
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
            py: "4px",
            lineHeight: "unset",
          },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
            lineHeight: "1",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
            lineHeight: "unset",
          },
        }}
      >
      </BaseDataGrid>
      {
        !!selectedOrderIds?.length && 
        <Box display="flex" flexWrap="nowrap" width="100%" sx={{marginTop: ".75rem"}}>
          <Button
            variant="contained"
            color="primary"
            sx={{fontWeight: "bold"}}
            onClick={() => submitCheckedOrders()}
          >
            Print Separately
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{fontWeight: "bold", marginLeft: ".5rem"}}
            onClick={() => printCheckedOrdersTogether()}
            disabled={selectedOrderIds?.length <= 1}
          >
            Print Together
          </Button>
        </Box>
      }
      {showDeleteModal && <DeleteModal 
        id={selectedForDeleteId}
        entityName={"this order"}
        apiPath={"deleteHeldOrder"}
        open={showDeleteModal}
        isafterSuccessFunction={true}
        afterSuccessFunction={() => {
          setShowDeleteModal(false);
          setSelectedForDeleteId(null);
        }}
        close={() => { 
          setShowDeleteModal(false); 
          setSelectedForDeleteId(null); 
        }}
      />}
      <div id="printSection" style={{position: "absolute", top: "-9999px"}}>
        {!!selectedOrderIds?.length && renderPrintContent()}
      </div>
    </div>
  );
}