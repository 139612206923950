import { Card, CardContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetPendingMealsQuery } from "store/apis/MealsApi";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import DeleteModal from "components/shared/deleteModal/DeleteModal";
import EditSetupModal from "components/shared/editSetupModal/EditSetupModal";
import DeleteMealModal from "components/shared/deleteMealModal/DeleteMealModal";

export default function PendingMeals({ availableMeals, currentUserData }) {

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedForDeleteId, setSelectedForDeleteId] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedForEdit, setSelectedForEdit] = useState(null); // we'll use the whole row data, not just Id
  const [isCustomDeleteOpen, setIsCustomDeleteOpen] = useState(false);
  const [selectedMealForDelete, setSelectedMealForDelete] = useState(null);

  const { 
    data: queuedMeals,
    isSuccess: queuedSuccess
  } = useGetPendingMealsQuery(
    {refetchOnMountOrArgChange: true}
  );

  // just trigger re-render when list was invalidated from delete here or add from parent
  useEffect(() => {}, [queuedSuccess]); 

  const renderQueuedMeals = () => {
    return queuedMeals?.map((meal) => {
      const diningDate = new Date(meal.diningDate);
      return (
        <TableRow key={meal.id} className="queueRow">
          <TableCell className="queueCell">{diningDate.toDateString()} {diningDate.toLocaleTimeString('en-US', { hour12: true, timeStyle: "short" })}</TableCell>
          <TableCell className="queueCell">{meal.seasonName}</TableCell>
          <TableCell className="queueCell">{meal.mealName}</TableCell>
          <TableCell className="queueCell">{meal.menuName}</TableCell>
          <TableCell className="queueCell">{meal.day}</TableCell>
          <TableCell className="queueCell">{meal.ordersTaken}</TableCell>
          <TableCell className="queueCell">{meal.isActive ? 'Active' : (
              <div style={{display: "flex", flexWrap: "nowrap", justifyContent: "space-around", alignItems: "center"}}>
                <IconButton 
                  sx={{
                    backgroundColor: "var(--yellowColor)", 
                    color: "#494a50", 
                    borderRadius: ".5rem", 
                    "&:hover": {backgroundColor: "#d5b538", color: "#fff"}
                  }}
                  onClick={() => {
                    setSelectedForEdit(meal);
                    setIsEditOpen(true);
                  }}  
                >
                  <EditIcon sx={{fontSize: "1rem"}} />
                </IconButton>
                { false &&
                  <IconButton sx={{backgroundColor: "var(--tealColor)", color: "#fff", "&:hover": {color: "#000"}}}>
                    <PrintIcon sx={{fontSize: "1rem"}} />
                  </IconButton> 
                }
                <IconButton 
                  sx={{
                    backgroundColor: "var(--redColor)", 
                    color: "#FFF", 
                    borderRadius: ".5rem",
                    "&:hover": {backgroundColor: "var(--darkGrayColor)"}
                  }} 
                  onClick={() => {
                    if (!meal?.ordersTaken) { // no orders against meal, default delete modal
                      setSelectedForDeleteId(meal.id);
                      setIsDeleteOpen(true);
                    } else { // custom warning modal
                      setSelectedMealForDelete(meal);
                      setIsCustomDeleteOpen(true);
                    }
                  }}
                >
                  <DeleteIcon sx={{fontSize: "1rem"}}/>
                </IconButton>  
              </div>
              )
            }
          </TableCell>
        </TableRow>
      );
    });
  }

  const afterDelete = () => {
    setSelectedForDeleteId(null);
    setIsDeleteOpen(false);
  }

  return (
    <>
      <Card sx={{margin: "1rem"}}>
        <CardContent>
          <Typography variant="h5" gutterBottom>Queued Meals</Typography>
          <Table sx={{borderRadius: "1rem", border: "1px solid black", borderCollapse: "separate"}}>
            <TableHead className="tableHead">
              <TableRow className="queueRow" sx={{fontWeight: "bold"}}>
                <TableCell className="queueCell">Dining Date</TableCell>
                <TableCell className="queueCell">Season</TableCell>
                <TableCell className="queueCell">Meal</TableCell>
                <TableCell className="queueCell">Menu</TableCell>
                <TableCell className="queueCell">Day</TableCell>
                <TableCell className="queueCell">Orders Taken</TableCell>
                <TableCell className="queueCell">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderQueuedMeals()}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      {isDeleteOpen && 
        <DeleteModal 
        id={selectedForDeleteId}
        open={isDeleteOpen} 
        entityName={"this meal"}
        apiPath={"deleteMeal"}
        isafterSuccessFunction={true}
        afterSuccessFunction={afterDelete}
        close={() => setIsDeleteOpen(() => false)}
        />
      }
      {isEditOpen && 
        <EditSetupModal 
          open={isEditOpen}
          meal={selectedForEdit}
          facilityId={currentUserData?.facilityId}
          availableMeals={availableMeals}
          handleClose={() => setIsEditOpen(() => false)}
        />
      }
      {isCustomDeleteOpen && 
        <DeleteMealModal 
          open={isCustomDeleteOpen}
          order={selectedMealForDelete}
          handleClose={() => setIsCustomDeleteOpen(() => false)}
        />
      }
    </>
  );
}