import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  facilityDiningAreas: null,
  lastDiningArea: {
    diningAreaId: null,
    diningAreaName: ""
  },
  heldViewLastDiningArea: {
    diningAreaId: null,
    diningAreaName: ""
  }
}

export const DiningAreaSlice = createSlice({
  name: "diningArea",
  initialState,
  reducers: {
    setFacilityDiningAreas: (state, action) => {
      state.facilityDiningAreas = {...action.payload};
    },
    setLastDiningArea: (state, action) => {
      state.lastDiningArea = {...action.payload};
    },
    setHeldViewLastDiningArea: (state, action) => {
      state.heldViewLastDiningArea = {...action.payload};
    }
  }
})

export const { setFacilityDiningAreas, setLastDiningArea, setHeldViewLastDiningArea } = DiningAreaSlice.actions;
export const selectFacilityDiningAreas = (state) => state?.diningArea?.facilityDiningAreas;
export const selectLastDiningArea = (state) => state?.diningArea?.lastDiningArea;
export const selectHeldViewLastDiningArea = (state) => state?.diningArea?.heldViewLastDiningArea;

export default DiningAreaSlice.reducer;