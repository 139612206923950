import { AppBar, Badge, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import Residents from "../residents/Residents";
import { Link, useLocation, useOutletContext, useParams } from "react-router-dom";
import Guest from "../guest/Guest";
import CooksTicket from "../cooksTicket/CooksTicket";
import HeldOrders from "../heldOrders/HeldOrders";
import Submitted from "../submitted/Submitted";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from '@mui/icons-material/People';
import ChatIcon from '@mui/icons-material/Chat';
import PanToolIcon from '@mui/icons-material/PanTool';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import "./TakeOrder.css";
import ActiveMeals from "../residents/ActiveMeals";
import Order from "../order/Order";
import { useGetHeldOrdersQuery } from "store/apis/OrdersApi";
import { useGetPermissionsQuery } from "store/apis/AccountApi";
import { useDispatch } from "react-redux";
import { setPermissions } from "store/slices/appSlice/AppSlice";

export default function TakeOrder() {
  let { residentId, tableSideSetupId } = useParams();
  const currentLocation = useLocation();  
  const currentUserData = useOutletContext();
  const isGuest = location.pathname?.toLowerCase()?.includes('guest');
  const dispatch = useDispatch();
  const [isDirectPrint, setIsDirectPrint] = useState(false);
  const [allowMealCardNotes, setAllowMealCardNotes] = useState(false);

  const setInitialTabValue = () => {    
    return checkTabValue();
  }

  // legacy used string "guest", but to stick with INT convention, using 0 to denote not a resident
  if (isGuest) { residentId = 0 }

  const checkTabValue = () => {
    if (currentLocation.pathname.includes('/takeorder/residents')) {
      return 0;
    } else if (currentLocation.pathname.includes('takeorder/guest')) {
      return 1;
    } else if (currentLocation.pathname.includes('takeorder/cooksticket')){
      return 2;
    } else if (currentLocation.pathname.includes('takeorder/heldorders')) {
      return 3;
    } else if (currentLocation.pathname.includes('takeorder/submitted')) {
      return 4;
    } else {
      return 0; //default on error
    }
  }
  const [tabValue, setTabValue] = useState(() => {
    const initialState = setInitialTabValue();
    return initialState;
  });

  const { data: heldOrders } = useGetHeldOrdersQuery();

  const { data: permissions } = useGetPermissionsQuery();


  useEffect(() => {
    if (permissions?.length) {
      dispatch(setPermissions(permissions));
      setIsDirectPrint(permissions.includes("Direct Print"));
      setAllowMealCardNotes(permissions.includes("Print MealCard Notes"))
    }
  }, [permissions])

  useEffect(() => {
    setTabValue(checkTabValue());
  }, [currentLocation]);

  const handlePrint = (htmlContent) => {
    const contentWindow = document.getElementById("printFrame").contentWindow;
    contentWindow.document.open();
    contentWindow.document.write(htmlContent.innerHTML);
    contentWindow.document.close();
    contentWindow.focus();
    contentWindow.print();
    contentWindow.close();
  }

  return (
    <>
      {/* hidden iframe to use for direct print from child content */}
      <iframe id="printFrame" style={{height: "0px", width: "0px", position: "absolute", display: "none"}}></iframe>
      <AppBar position="static" sx={{
        borderRadius: "0 !important", 
        borderTop: "1px solid grey",
        boxShadow: "4px 4px .5rem rgba(0, 0, 0, .35) !important"}}>
        <Tabs 
          variant="fullWidth" 
          value={tabValue} 
          TabIndicatorProps={{
            sx: {
              backgroundColor: "#ff3f28 !important",
            }
          }}>
          <Tab component={Link} to={"/takeorder/residents"} className="order-tab" label="Resident" icon={<PersonIcon />} iconPosition="top"/>
          <Tab component={Link} to={"/takeorder/guest"} className="order-tab" label="Guest / Employee" icon={<PeopleIcon />} iconPosition="top"/>
          <Tab component={Link} to={"/takeorder/cooksticket"}className="order-tab" label="Cook's Ticket" icon={<ChatIcon />} iconPosition="top"/>
          <Tab 
            component={Link} 
            to={"/takeorder/heldorders"}
            className="order-tab" 
            label={
              <Badge className="heldOrders-badge" invisible={!heldOrders?.length} color="secondary" badgeContent={heldOrders?.length}>Held Orders</Badge>
            } 
            icon={<PanToolIcon />} 
            iconPosition="top"/>
          <Tab component={Link} to={"/takeorder/submitted"}className="order-tab" label="Submitted" icon={<AssignmentTurnedInIcon />} iconPosition="top"/>
        </Tabs>
      </AppBar>
      {currentLocation.pathname === '/takeorder/residents' && 
        <Residents heldOrders={heldOrders} />
      }
      {currentLocation.pathname.includes('/takeorder/residents/meals') &&
        <ActiveMeals /> 
      }
      {(currentLocation.pathname.includes('/takeorder/residents/order') || currentLocation.pathname.includes('/takeorder/guest/order')) &&
        <Order 
          residentId={residentId} 
          tableSideSetupId={tableSideSetupId} 
          isGuest={isGuest} 
          isDirectPrint={isDirectPrint}
          allowMealCardNotes={allowMealCardNotes}
          currentUserData={currentUserData}
          handlePrint={handlePrint}
        />
      }
      {currentLocation.pathname === '/takeorder/guest' && 
        <Guest />
      }
      {currentLocation.pathname === '/takeorder/cooksticket' && 
        <CooksTicket 
          isDirectPrint={isDirectPrint} 
          handlePrint={handlePrint} 
        />
      }
      {currentLocation.pathname === '/takeorder/heldorders' && 
        <HeldOrders 
          heldOrders={heldOrders} 
          currentUserData={currentUserData}
          isDirectPrint={isDirectPrint}
          handlePrint={handlePrint} 
        />
      }
      {currentLocation.pathname === '/takeorder/submitted' && 
        <Submitted />
      }
    </>
  )
}