import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { MEALS } = API_ENDPOINTS;

export const MealsApi = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableMeals: builder.query({
      query: () => `/${MEALS}/AvailableMeals`,
      transformResponse: (response) => response?.data,
    }),
    getMealAddOns: builder.query({
      query: ({residentId, setupId, isGuest}) => 
        `/${MEALS}/MealAddOns?residentId=${residentId}&tableSideSetupId=${setupId}&isGuest=${isGuest}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
        ? [
          {type: MEALS, id: response},
          MEALS
        ]
        : [MEALS];
      }
    }),
    getPendingMeals: builder.query({
      query: () => `/${MEALS}/PendingMeals`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
        ? [
            ...response.map(({ id }) => {
              return {type: MEALS, id: id}
            }),
            MEALS
        ] : [MEALS]
      },
      forceRefetch({currentArg, prevArg}) { 
        return currentArg !== prevArg; } 
    }),
    insertMeal: builder.mutation({
      query: (data) => ({
        url: `${MEALS}`,
        method: "POST",
        body: data
      }),
      invalidatesTags: [MEALS]
    }),
    updateMeal: builder.mutation({
      query: (data) => ({
        url: `${MEALS}`,
        method: "PUT",
        body: data
      }),
      invalidatesTags: [MEALS]
    }),
    deleteMeal: builder.mutation({
      query: (setupId) => ({
        url: `${MEALS}/DeleteMeal/${setupId}`,
        method: "DELETE",
        body: setupId
      }),
      invalidatesTags: [MEALS]
    })
  })
});

export const {
  useGetAvailableMealsQuery,
  useGetMealAddOnsQuery,
  useGetPendingMealsQuery,
  useInsertMealMutation,
  useUpdateMealMutation,
  useDeleteMealMutation,
} = MealsApi;